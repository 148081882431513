<template>
  <div class="container" style="background-color: #eececf">
    <div class="top-block">
      <img
        src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/h5/logo.png"
        mode="widthFix"
        class="logo"
      />
    </div>
    <div v-if="noMessage || dataForm.id">
      <div v-if="!noMessage">
        <!-- 首次查询 -->
        <div
          v-if="!!dataForm.firstQuery"
          class="resBox"
          style="
            margin: 0 auto;
            margin-top: 0.7rem;
            font-size: 0.46rem;
            line-height: 1rem;
          "
        >
          <div class="resTitle">
            <img
              src="../assets/bodorme/fwresult.png"
              alt=""
              style="width: 5rem"
            />
          </div>
          <div class="goodsText">
            您所查询的防伪码:{{ fwIscode }}的产品为正牌产品标识，请放心使用。
          </div>
        </div>
        <!-- 二次查询 -->
        <div
          v-if="!dataForm.firstQuery"
          class="resBox"
          style="
            margin: 0 auto;
            margin-top: 0.7rem;
            font-size: 0.46rem;
            line-height: 1rem;
          "
        >
          <div class="resTitle">
            <img
              src="../assets/bodorme/fwresult.png"
              alt=""
              style="width: 5rem"
            />
          </div>
          <div class="goodsText">
            <div style="word-break: break-all; overflow-wrap: break-word">
              您所查询的防伪码:{{ fwIscode }}的产品为正牌产品标识，请放心使用。
            </div>
            当前防伪码已被查询
            <span style="color: red"> {{ dataForm.scanCodeNumber }}</span>
            次，首次查询时间为：<span style="font-size: 38rpx"
              >{{ dataForm.firstScanCodeTime }}
            </span>
            ，如与您的首次查询时间不符，请当心该产品是假冒产品，如有疑问请与“Bodorme贝德美”微信公众平台客服联系。
          </div>
        </div>
      </div>
    </div>
    <!-- 查询不到 -->
    <div
      v-if="noMessage"
      class="resBox"
      style="margin-top: 1.7rem; font-size: 0.46rem; line-height: 1rem"
    >
      <div class="resTitle">
        <img src="../assets/bodorme/fwresult.png" alt="" style="width: 5rem" />
      </div>
      <div class="goodsText" style="margin-top: 0.5rem">
        {{ noMessage }}
      </div>
    </div>
     <img
      src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/h5/bottom.png"
      alt=""
      class="bottomimg"
    />
  </div>
</template>

<script>
import "./flexible";
import { getruleByfwcode } from "@/api/index";
export default {
  data() {
    return {
      // ccc: '<p class="ql-align-center"><span style="color: rgb(0, 176, 80);">恭喜，您查询的是本公司正品！</span></p><p class="ql-align-center">防伪码：{code}</p><p class="ql-align-center">  查询次数：{hits}</p><p class="ql-align-center">首次查询时间：&nbsp;{firstQueryTime}</p>',

      dataForm: {},
      fwCode: "",
      browser: "",
      ipAddress: "",
      noMessage: "",
      fwIscode: "",
    };
  },
  mounted() {
    //  初始化请求公众号参数
    this.postInfo(this.$route.query.res1);
  },
  methods: {
    //  扫描二维码发送请求
    async postInfo(Iscode) {
      this.fwIscode = Iscode;
      const param = {
        browser: this.browser,
        ipAddress: this.ipAddress,
        code: Iscode,
        appId: "wx45ac039d2060b806",
      };

      try {
        const res = await getruleByfwcode(param);

        if (res.code == "200") {
          this.dataForm = res.data;
        } else {
          this.noMessage = res.msg;
        }
      } catch (error) {
        console.log(error, "rrrerror");
      }
    },
  },
};
</script>

<style scoped>
.bottomimg {
  position: absolute;
  bottom: -.8rem;
  left: 0px;
  width: 100%;
  z-index: 999;
  pointer-events: none;
}
.centerToast {
  z-index: 100000000001 !important;
}

.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  background: #90aeca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.logo {
  width: 4rem;
  margin-top: 1.2rem;
}
.tip {
  width: 100%;
  font-size: 0.6rem;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-top: 2rem;
}
button {
  border-radius: 0px;
  width: 70%;
  margin: 0;
  padding: 0;
}

.resBox {
  /* margin-top: .5rem; */
  width: 68%;
  border-radius: 0.2rem;
  padding: 1rem 0.5rem 0.6rem 0.5rem;
  position: relative;
  /* background-color: rgb(218, 228, 238, 0.55); */
  text-align: left;
  border: 1px solid #fff;
}
.resTitle {
  position: absolute;
  top: -0.5rem;
  z-index: 999;
  left: 50%;
  margin-left: -2.5rem;
  text-align: center;
  font-family: PingFang SC;
}
.fwText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
}

.fwText :nth-child(1) {
  text-align: center;
  margin-top: 0.38rem;
  margin-bottom: 0.28rem;
}
.goodsText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
  margin-top: 0.2rem;
}
</style>
