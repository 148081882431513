import VueRouter from "vue-router";
import index from "../pages/index.vue";
import Gzh from "../pages/Gzh.vue";
import detail from "../pages/detail.vue";
import carrierBodorme from "../pages/carrier/bodorme.vue";
import carrierSkino from "../pages/carrier/skino.vue";
import carrierqiHe from "../pages/carrier/qiHe.vue";
import carrierxixi from "../pages/carrier/xixi.vue";
// 公众号
import SkinoGzh from "../pages/Gzh/Skino.vue";
import skinoDetail from "../pages/Gzh/skinoDetail.vue";
import QiheGzh from "../pages/Gzh/Qihe.vue";
import qiheDetail from "../pages/Gzh/qiheDetail.vue";
import xixiGzh from "../pages/Gzh/xixi.vue";
import xixiDetail from "../pages/Gzh/xixiDetail.vue";
import klinicGzh from "../pages/Gzh/klinic.vue";
import klinicDetail from "../pages/Gzh/klinicDetail.vue";

// 创建并暴露一个路由器
export default new VueRouter({
  // mode: "history",
  routes: [
    {
      path: "/",
      component: index,
    },
    {
      path: "/Gzh",
      name: "Gzh",
      component: Gzh,
    },
    {
      path: "/detail",
      name: "detail",
      component: detail,
    },
    {
      path: "/carrier/bodorme",
      name: "carrierbodorme",
      component: carrierBodorme,
    },
    {
      path: "/carrier/skino",
      name: "carrierskino",
      component: carrierSkino,
    },
    {
      path: "/carrier/qiHe",
      name: "carrierqiHe",
      component: carrierqiHe,
    },
    {
      path: "/carrier/xixi",
      name: "carrierxixi",
      component: carrierxixi,
    },
    {
      path: "/SkinoGzh",
      name: "SkinoGzh",
      component: SkinoGzh,
    },
    {
      path: "/skinoDetail",
      name: "skinoDetail",
      component: skinoDetail,
    },
    {
      path: "/QiheGzh",
      name: "QiheGzh",
      component: QiheGzh,
    },
    {
      path: "/qiheDetail",
      name: "qiheDetail",
      component: qiheDetail,
    },
    {
      path: "/xixiGzh",
      name: "xixiGzh",
      component: xixiGzh,
    },
    {
      path: "/xixiDetail",
      name: "xixiDetail",
      component: xixiDetail,
    },
    {
      path: "/klinicGzh",
      name: "klinicGzh",
      component: klinicGzh,
    },
    {
      path: "/klinicDetail",
      name: "klinicDetail",
      component: klinicDetail,
    },
  ],
});
