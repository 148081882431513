<template>
  <div class="Container" style="background-color: #eececf">
  
      <img
        src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/h5/logo.png"
        mode="widthFix"
        class="logo"
      />
      <div class="tip">贝德美官方防伪查询</div>

    <div>
      <div class="box1" @click="onScan1">
        <span style="font-weight: bold">点击扫描外包装二维码查询</span>
      </div>
      <div class="box2">
        <img
          style="
            width: 0.5rem;
            height: 0.5rem;
            margin-left: 0.25rem;
            margin-right: 0.3rem;
          "
          src="../assets/edit.png"
          alt=""
        />
        <input
          placeholder="请输入商品防伪码"
          v-model="Iscode"
          style="border: none; background-color: rgba(0, 0, 0, 0)"
        />
        <span style="font-weight: bold; margin-left: 0.1rem" @click="onScan2"
          >查询</span
        >
      </div>
    </div>
    <img
      src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/h5/bottom.png"
      alt=""
      class="bottomimg"
    />
  </div>
</template>

<script>
import "./flexible";
import { getAWxJsApi, wxLogin, verifyGetRule } from "@/api/index";
export default {
  data() {
    return {
      fwCode: "",
      JumpMode: "iphoneX",
      Iscode: "",
      form: {},
    };
  },
  components: {},
  async created() {
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const oScript = document.createElement("script");
        oScript.type = "text/javascript";
        oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
        oScript.onload = resolve;
        oScript.onerror = reject;
        document.body.appendChild(oScript);
      });
    };

    // 等待脚本加载完成
    loadScript()
      .then(() => {
        // 在脚本加载完成后执行需要的操作
        this.wxmini();
      })
      .catch((error) => {});
  },

  async mounted() {},
  methods: {
    //  让h5具备微信能力
    async wxmini() {
      const result = await getAWxJsApi({
        url: location.href.split("#")[0],
        appId: "wxeef9f1dcc6d7f5e3",
      });
      if (result.code === 200) {
        const Authorization = JSON.parse(localStorage.getItem("token"));
        if (!Authorization) {
          // 静默登录
          await this.getLogin();
        }
        const result2 = await verifyGetRule({ appId: "wxeef9f1dcc6d7f5e3" });
        this.form = result2.data;
      }

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: "wxeef9f1dcc6d7f5e3", // 必填，公众号的唯一标识
        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
        signature: result.data.signature, // 必填，签名
        jsApiList: ["getLocalIPAddress", "getSystemInfo", "scanQRCode"], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      wx.ready(function (res) {
        console.log(res, ":success");
      });
      wx.error(function (res) {
        console.log(res, ":fail");
      });
    },

    // 静默登录处理code
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    // 静默登录
    async getLogin() {
      var APPID = "wxeef9f1dcc6d7f5e3";
      var code = this.getQueryString("code");
      var query = encodeURIComponent(window.location.search.substr(1)) || null;
      const redirectUri = encodeURIComponent(window.location.href);
      var state = this.getQueryString("state") || null;
      if (state) {
        state = decodeURIComponent(state);
      }

      if (code) {
        // 进行静默登录
        const res = await wxLogin({ code, appId: APPID });
        if (res.code === 200) {
          localStorage.setItem("token", JSON.stringify(res.data.token));
        }
      } else {
        location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          APPID +
          "&redirect_uri=" +
          redirectUri +
          "&response_type=code&scope=snsapi_base&state=" +
          query +
          "#wechat_redirect";
      }
    },

    // 扫一扫
    onScan1() {
      const _that = this;
      wx.scanQRCode({
        needResult: 1,
        success: function (res) {
          const oldIscode = res.resultStr.split("?")[1];
          const Iscode = oldIscode.split("=")[1];
          _that.$router.push({
            name: "detail",
            query: {
              res1: Iscode,
            },
          });
        },
      });
    },
    onScan2() {
      if (this.Iscode && this.Iscode?.length > 15) {
        // 跳转到扫码成功页面
        const _that = this;
        _that.$router.push({
          name: "detail",
          query: {
            res1: this.Iscode,
          },
        });
      } else {
        alert("防伪码长度不得小于15位");
      }
    },
  },
};
</script>

<style scoped>
.bottomimg {
  position: absolute;
  bottom: -0.7rem;
  left: 0px;
  width: 100%;
  z-index: 999;
  pointer-events: none;
}

.logo {
  width: 4rem;
  margin-top: 1.3rem;
}
.Container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  font-family: PingFang SC;
  min-height: 100vh;
  padding-left: 0px;
  overflow: hidden;
}
.top-guar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.bottom-guar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.tip {
  width: 100%;
  font-size: 0.58rem;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #585a5b;
  margin-top: 1rem;
}
.codema {
  width: 34%;
  margin-top: 1rem;
}

.smallbody {
  margin-top: 1.2rem;
  font-size: 0.6rem;
  color: #776a6b;
  font-family: PingFang SC;
}

.box1 {
  font-size: 0.4rem;
  width: 70%;
  border: 1px solid #fff;
  padding-top: 0.22rem;
  padding-bottom: 0.18rem;
  color: #585a5b;
  background-color: #e1e1e1;
  margin: 0 auto;
  margin-top: 2rem;
  box-shadow: 0px 0px 0.3125rem rgba(255, 255, 255, 0.5);
  font-family: PingFang SC;
  background-color: #fff;
}

.box2 {
  font-size: 0.4rem;
  width: 70%;
  border: 1px solid rgba(255, 255, 255, 0.753);
  padding-top: 0.19rem;
  padding-bottom: 0.19rem;
  color: #585a5b;
  background-color: #e1e1e1;
  margin: 0 auto;
  margin-top: 1.5625rem;
  box-shadow: 0px 0px 0.3125rem rgba(255, 255, 255, 0.5);
  font-family: PingFang SC;
  display: flex;
  align-items: center;
  background-color: #fff;
}
</style>
