<template>
  <div v-if="noMessage || dataForm.id">
    <div
      v-if="!noMessage"
      class="container"
      style="
        background-image: url(https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/qihe/newBackGround2.png);
        background-size: 100%;
        background-repeat: no-repeat;
      "
    >
      <div
        style="
          position: absolute;
          top: 5.75rem;
          font-size: 0.3rem;
          left: 5.55rem;
        "
      >
        {{ dataForm.scanCodeNumber }}
      </div>
      <div v-if="!noMessage">
        <!-- 首次查询 -->
        <div
          v-if="!!dataForm.firstQuery"
          class="resBox"
          style="
            margin: 0 auto;
            margin-top: 7.5rem;
            font-size: 0.46rem;
            line-height: 1rem;
          "
        >
          <div class="goodsText">
            {{ fwIscode }}您所查询的是：启呵系列产品,为正牌产品标识,请放心使用。
          </div>
        </div>
        <!-- 二次查询 -->
        <div
          v-if="!dataForm.firstQuery"
          class="resBox"
          style="
            margin: 0 auto;
            margin-top: 7.5rem;
            font-size: 0.46rem;
            line-height: 1rem;
          "
        >
          <div class="goodsText">
            {{ fwIscode }}该防伪码已经查询过{{
              dataForm.scanCodeNumber
            }}次，首次查询时间是{{
              dataForm.firstScanCodeTime
            }}，为防止假冒，防伪码只能有效查询一次，如果您首次查询时间与上述时间不符，
            请当心该产品是假冒产品。如有疑问请拨打咨询电话 400-757-1997。
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="noMessage"
      class="container"
      style="
        background-image: url(https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/qihe/backGround3.jpg);
        background-size: 100%;
        background-repeat: no-repeat;
      "
    >
      <!-- 查询不到 -->
      <div
        class="resBox"
        style="margin-top: 7.7rem; font-size: 0.46rem; line-height: 1rem"
      >
        <div class="goodsText" style="margin-top: 0.5rem;color:#000">
          {{ noMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./flexible";
import { getruleByfwcode } from "@/api/index";
export default {
  data() {
    return {
      // ccc: '<p class="ql-align-center"><span style="color: rgb(0, 176, 80);">恭喜，您查询的是本公司正品！</span></p><p class="ql-align-center">防伪码：{code}</p><p class="ql-align-center">  查询次数：{hits}</p><p class="ql-align-center">首次查询时间：&nbsp;{firstQueryTime}</p>',

      dataForm: {},
      fwCode: "",
      browser: "",
      ipAddress: "",
      noMessage: "",
      fwIscode: "",
    };
  },
  mounted() {
    //  初始化请求公众号参数
    this.postInfo(this.$route.query.res1);
  },
  methods: {
    //  扫描二维码发送请求
    async postInfo(Iscode) {
      this.fwIscode = Iscode;
      const param = {
        browser: this.browser,
        ipAddress: this.ipAddress,
        code: Iscode,
        appId: "wx9732c490c544d6dc",
      };

      try {
        const res = await getruleByfwcode(param);

        if (res.code == "200") {
          this.dataForm = res.data;
        } else {
          this.noMessage = res.msg;
        }
      } catch (error) {
        console.log(error, "rrrerror");
      }
    },
  },
};
</script>

<style scoped>
.centerToast {
  z-index: 100000000001 !important;
}

.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.logo {
  width: 4rem;
  margin-top: 1.2rem;
}
.tip {
  width: 100%;
  font-size: 0.6rem;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  margin-top: 2rem;
}
button {
  border-radius: 0px;
  width: 70%;
  margin: 0;
  padding: 0;
}

.resBox {
  width: 68%;
  border-radius: 0.2rem;
  padding: 1rem 0.5rem 0.6rem 0.5rem;
  position: relative;
  text-align: left;
}
.resTitle {
  position: absolute;
  top: -0.5rem;
  z-index: 999;
  left: 50%;
  margin-left: -2.5rem;
  text-align: center;
  font-family: PingFang SC;
}
.fwText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
}

.fwText :nth-child(1) {
  text-align: center;
  margin-top: 0.38rem;
  margin-bottom: 0.28rem;
}
.goodsText {
  line-height: 0.6rem;
  font-family: PingFang SC;
  font-size: 0.35rem;
  text-align: left;
  margin-top: 0.2rem;
  color: #fff;
}
</style>
