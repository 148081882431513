import axios from "axios";

// 1、配置基础路径和超时限制
const instance = axios.create({
  // baseURL: "https://fw-app-api.skino.cn", //公共的基础路径Z
  baseURL: "https://fw-app-api.haiyn.com", //公共的基础路径Z
  // baseURL: "http://10.1.70.23:8092/", //公共的基础路径
  timeout: 20000,
});

// 2、添加进度条信息  nprogress（开启进度条）
instance.interceptors.request
.use(
  // 请求拦截器的成功
  (config) => {
    // if (config.url !== "/api/front/verify/getRule") {
      const Authorization = JSON.parse(localStorage.getItem("token"));
      console.log("Authorization<<<<",Authorization);
      if (Authorization) {
        config.headers["Authorization"] = Authorization;
        // config.headers["content-type"] = "application/json;charset=UTF-8";
      }
    // }

    return config;
  }
);

// 3、返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    alert(error)
    return Promise.reject(error); //让错误信息继续传递下去
  }
);

export default instance;
