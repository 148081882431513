// 引入并暴露
// 先引入再暴露
// 可以理解为先引入到这个文件，再分别暴露出去，但是下面这个写法是错的，
// 可以这么理解但是不能这么写
// import trademark from "./trademark";  简写
// import {default as trademark} from './trademark'  //全写
// export trademark

import request from "@/utils/request";

// 查询列表
export function getPlatform(query) {
  return request({
    url: "/api/front/verify/getPlatform",
    method: "post",
    data: query,
  });
}
// 根据防伪码查询防伪信息
export function getruleByfwcode(query) {
  return request({
    url: "/api/front/verify/get/rule",
    method: "post",
    data: query,
  });
}

// 查询列表
export function getAWxJsApi(query) {
  return request({
    url: "/api/front/common/getWxJsApi",
    method: "post",
    data: query,
  });
}
// 微信公众号进行静默登录
export function wxLogin(query) {
  return request({
    url: "/api/front/user/getUserOpenId",
    method: "post",
    data: query,
  });
}
// 微信公众号进行静默登录
export function wxRedirect(query) {
  return request({
    url: "/api/front/user/wx/autho",
    method: "post",
    params: query,
  });
}
// 根据appid获取信息
export function verifyGetRule(query) {
  return request({
    url: "/api/front/verify/getRule",
    method: "post",
    data: query,
  });
}
