import { render, staticRenderFns } from "./klinic.vue?vue&type=template&id=ea35eca0&scoped=true&"
import script from "./klinic.vue?vue&type=script&lang=js&"
export * from "./klinic.vue?vue&type=script&lang=js&"
import style0 from "./klinic.vue?vue&type=style&index=0&id=ea35eca0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea35eca0",
  null
  
)

export default component.exports