<template>
  <div v-if="noMessage || dataForm.id">
    <div
      class="container"
      v-if="!noMessage"
      style="
        background-image: url('https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/xixi/newbackGround2.png');
        background-size: 100%;
        background-repeat: no-repeat;
      "
    >
      <div v-if="!noMessage" class="resBox">
        <div
          v-if="!!dataForm.firstQuery"
          style="
            margin: 0 auto;

            line-height: 1rem;
          "
        >
          <div class="goodsText">
            您所查询的是：西西来了系列产品 为正牌产品标识 请放心使用
          </div>
        </div>
        <div
          v-if="!dataForm.firstQuery"
          style="margin: 0 auto; line-height: 1rem"
        >
          <div class="goodsText">
            {{ fwIscode }} 该防伪码已查询过{{
              dataForm.scanCodeNumber
            }}次,首次查询时间是{{ dataForm.firstScanCodeTime }}
            ,为防止假冒，防伪码只能有效查询一次，如果您首次查询的时间与上述时间不符，
            请当心该产品是假冒产品。如有疑问请拨打咨询电话400-757-1997。
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      v-if="noMessage"
      style="
        background-image: url('https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/xixi/backGround3.jpg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      "
    >
      <div class="resTitle">
        <img
          src="../../assets/Gzh/Xixi/xixilogo.png"
          alt=""
          style="width: 4.5rem; height: auto"
        />
        <img
          src="../../assets/Gzh/Xixi/title2.png"
          alt=""
          style="width: 4.5rem; height: auto; margin-top: 2rem"
        />
      </div>
      <!-- 查询不到 -->
      <div v-if="noMessage" class="resBox2">
        <div class="goodsText" style="margin-top: 0.5rem">
          {{ noMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./flexible";
import { getruleByfwcode } from "@/api/index";
export default {
  data() {
    return {
      dataForm: {},
      fwCode: "",
      browser: "",
      ipAddress: "",
      noMessage: "",
      fwIscode: "",
    };
  },
  mounted() {
    //  初始化请求公众号参数
    this.postInfo(this.$route.query.res1);
  },
  methods: {
    //  扫描二维码发送请求
    async postInfo(Iscode) {
      this.fwIscode = Iscode;
      const param = {
        browser: this.browser,
        ipAddress: this.ipAddress,
        code: Iscode,
        appId: "wx2829bf23b7ec63eb",
      };

      try {
        const res = await getruleByfwcode(param);

        if (res.code == "200") {
          this.dataForm = res.data;
        } else {
          this.noMessage = res.msg;
        }
      } catch (error) {
        console.log(error, "rrrerror");
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  background: #90aeca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.logo {
  width: 4rem;
  margin-top: 1.2rem;
}

.resBox {
  position: absolute;
  top: 9.4rem;
  left: 1.8rem;
  text-align: left;
  color: #595858;
  font-family: siyuanMed;
  width: 66%;
}
.resBox2 {
  position: absolute;
  top: 9.4rem;
  left: 1.8rem;
  text-align: left;
  color: #595858;
  font-family: siyuanMed;
  width: 66%;
}
.resTitle {
  position: absolute;
  top: 1.5rem;
  z-index: 999;
  left: 50%;
  margin-left: -2.5rem;
  text-align: center;
  font-family: PingFang SC;
  width: 5rem;
}
.fwText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
}

.fwText :nth-child(1) {
  text-align: center;
  margin-top: 0.38rem;
  margin-bottom: 0.28rem;
}
.goodsText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.35rem;
  text-align: left;
  margin-top: 0.2rem;
}
</style>
