<template>
  <div class="container" v-if="noMessage || dataForm.id">
    <div v-if="!noMessage" class="resBox">
      <div class="resTitle">
        <img
          src="../../assets/Gzh/Klinic/list/title2.png"
          alt=""
          style="width: 4.5rem; height: auto"
        />
      </div>
      <div class="goodsText">
        <div>您所查询的防伪码:</div>
        {{ fwIscode }}
        的产品为正牌产品标识,请放心使用,如有疑问请与"一叶子新零售服务号"微信公众平台客服联系。
      </div>
    </div>
    <!-- 查询不到 -->
    <div v-if="noMessage" class="resBox2">
      <div class="resTitle">
        <img
          src="../../assets/Gzh/Klinic/list/title2.png"
          alt=""
          style="width: 4.5rem; height: auto"
        />
      </div>
      <div class="goodsText" style="margin-top: 0.5rem">
        {{ noMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import "./flexible";
import { getruleByfwcode } from "@/api/index";
export default {
  data() {
    return {
      dataForm: {},
      fwCode: "",
      browser: "",
      ipAddress: "",
      noMessage: "",
      fwIscode: "",
    };
  },
  mounted() {
    //  初始化请求公众号参数
    this.postInfo(this.$route.query.res1);
  },
  methods: {
    //  扫描二维码发送请求
    async postInfo(Iscode) {
      this.fwIscode = Iscode;
      const param = {
        browser: this.browser,
        ipAddress: this.ipAddress,
        code: Iscode,
        appId: "wx5e4cbfa2d994af38",
      };

      try {
        const res = await getruleByfwcode(param);

        if (res.code == "200") {
          this.dataForm = res.data;
        } else {
          this.noMessage = res.msg;
        }
      } catch (error) {
        console.log(error, "rrrerror");
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-image: url("https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/klinic/background2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.logo {
  width: 4rem;
  margin-top: 1.2rem;
}

.resBox {
  position: absolute;
  top: 10rem;
  left: 1.8rem;
  text-align: left;
  color: #595858;
  font-family: siyuanMed;
  width: 66%;
}
.resBox2 {
  position: absolute;
  top: 10.5rem;
  left: 1.8rem;
  text-align: left;
  color: #595858;
  font-family: siyuanMed;
  width: 66%;
}
.resTitle {
  position: absolute;
  top: -1.5rem;
  z-index: 999;
  left: 50%;
  margin-left: -2.5rem;
  text-align: center;
  font-family: PingFang SC;
}
.fwText {
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
}

.fwText :nth-child(1) {
  text-align: center;
  margin-top: 0.38rem;
  margin-bottom: 0.28rem;
}
.goodsText {
  color: #fff;
  line-height: 0.8rem;
  font-family: PingFang SC;
  font-size: 0.42rem;
  text-align: left;
  margin-top: 0.2rem;
}
</style>
