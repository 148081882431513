import { render, staticRenderFns } from "./bodorme.vue?vue&type=template&id=2f6bbeae&scoped=true&"
import script from "./bodorme.vue?vue&type=script&lang=js&"
export * from "./bodorme.vue?vue&type=script&lang=js&"
import style0 from "./bodorme.vue?vue&type=style&index=0&id=2f6bbeae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6bbeae",
  null
  
)

export default component.exports