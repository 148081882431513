<template>
  <div class="app-container">
    <div class="main">
      <img src="../../assets/carrier/main.png" style="width: 100%" />
      <!-- 入口button -->
      <div class="button1">
        <a href="https://wx.cx312.com/custom/5507/bodorme/scan_new.html">
          <img src="../../assets/carrier/button1.png" style="width: 100%" />
        </a>
      </div>
      <div class="button2">
        <!-- <img
          v-if="!wxShow"
          src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/carrier/button2.png"
          style="width: 100%"
        /> -->
        <div>
          <wx-open-launch-weapp
            id="launch-btn"
            appid="wx45ac039d2060b806"
            :username="form.page"
            :path="`pages/index/index`"
          >
            <script type="text/wxtag-template">
              <image src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/carrier/button2.png" style="width:172px;"> </image>
            </script>
          </wx-open-launch-weapp>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../../assets/carrier/bottom.png" style="width: 100%" />
    </div>
  </div>
</template>

<script>
import { getAWxJsApi } from "@/api/index";
export default {
  data() {
    return {
      form: {},
      wxShow: false,
    };
  },
  mounted() {
    const oScript = document.createElement("script");
    oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    oScript.type = "text/javascript";
    oScript.onload = this.wxmini;
    document.body.appendChild(oScript);
  },
  methods: {
    async wxmini() {
      const result = await getAWxJsApi({
        url: window.location.href.split("#")[0],
        appId: "wxeef9f1dcc6d7f5e3",
      });
      this.wxShow = true;

      this.ISdata = result.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: "wxeef9f1dcc6d7f5e3", // 必填，公众号的唯一标识
        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
        signature: result.data.signature, // 必填，签名
        jsApiList: [
          "getLocalIPAddress",
          "getSystemInfo",
          "chooseImage",
          "previewImage",
        ], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      wx.ready(function (res) {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        // alert(res);
      });
    },
  },
};
</script>

<style scoped>
.app-container {
  background-image: url("https://img-scrm.oss-cn-hangzhou.aliyuncs.com/pda/miniapp/bodorme/carrier/bg.png");
  width: 100%;
  height: 100vh;
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #eececf;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.main {
  width: 70%;
  margin-top: 3.4rem;
  position: relative;
}

.button1 {
  width: 62%;
  position: absolute;
  top: 3.5rem;
  right: 0px;
}

.button2 {
  width: 62%;
  position: absolute;
  top: 7.1rem;
  right: 0px;
}

.bottom {
  position: absolute;
  left: 0;
  bottom: -0.4rem;
  width: 100%;
  /* background-color: #eececf; */
  pointer-events: none;
}
</style>
